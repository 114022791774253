import Alpine from 'alpinejs'

window._ = require('lodash');

window.Popper = require('popper.js').default;

window.axios = require('axios');

window.Alpine = Alpine
Alpine.start()

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
